@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: NotoSansRegular;
  src: url("./fonts/NotoSansRegular.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: NotoSansBold;
  src: url("./fonts/NotoSansBold.ttf") format("truetype");
  font-weight: Bold;
}

@layer base {
  html {
    font-family: NotoSansRegular;
  }
}

/* .ag-body-viewport-wrapper.ag-layout-normal {
  overflow-x: scroll;
  overflow-y: scroll;
}
.ag-body-viewport-wrapper.ag-layout-normal::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 8px;
  height: 8px;
}
.ag-body-viewport-wrapper.ag-layout-normal::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgb(34, 60, 120);
  box-shadow: 0 0 1px rgba(255,255,255,.5);
} */
.ag-root {
  overflow-x: scroll;
  overflow-y: scroll;
}
.ag-root ::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 8px;
  height: 8px;
}
.ag-root ::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgb(34, 60, 120);
  box-shadow: 0 0 1px rgba(255,255,255,.5);
}​

.triangle {
  line-height: 1;
  width:  15px;
  height: 24px;
  background-color: rgb(234 179 8);
  text-align: left;
  display: inline-block;
  &:hover {
    background-color: rgb(234 179 8);
  }

  &:before,
  &:after {
    content: '';
    position: absolute;
    background-color: inherit;
    width:  100%;
    height: 100%;
  }

  &.right { 
    transform: rotate(30deg) skewX(-30deg) scale(1,.866); 
    transform-origin: 85% 85%; 
    border-top-right-radius: 20%;
  }

  &:before { transform: rotate(-135deg) skewX(-45deg) scale(1.414,.707) translate(0,-50%); }
  &:after  { transform: rotate(135deg) skewY(-45deg) scale(.707,1.414) translate(50%); }
}

.arrow-left {
  position: absolute;
  width: 100px;
  height: 24px;
  right: 20px;
  background: black;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  border-radius: 10px;
}

.cover {
  position: absolute;
  height: 100px;
  width: 40px;
  overflow: hidden;
}

iframe {
  display: none;
}

thead {
  @apply shadow-[0_0px_8px_0px_rgba(0,0,0,0.15)] rounded-[8px];
}

input[type="checkbox"]:checked {
  background-color: white;
  border-color: #223c78;
}

.loan-card-text {
    max-width: 150px;
    overflow: hidden;
    white-space:nowrap;
    text-overflow:ellipsis;
}

.mantine-Checkbox-inner svg {
  color: #223c78 !important;
}

.mantine-1iq0i96::placeholder {
  color: #223c78;
}

.mantine-18eradl {
  color: #223c78;
  font-weight: 600;
  border-color: #223c78;
}

.mantine-vvp47s {
  border-color: transparent;
}

.mantine-vvp47s:focus,
.mantine-vvp47s:focus-within {
  border-color: transparent;
}

.mantine-37ngpr9hv {
  justify-content: center;
}

.mantine-vvp47s::placeholder {
  color: #656565;
  font-weight: 600;
  padding-left: 20px;
}

/*FilledBtn Styles - single scrub*/
.mantine-1lgsl26 {
  background-color: #223c78;
}

/*DropZone Styles*/
.mantine-bx0zqe {
  border: 2px dashed #2bace2;
  border-radius: 24px;
  opacity: 1;
}

/*SearchInput Styles*/
.mantine-1w8ivjd {
  border: 1px solid #223c78;
}
.mantine-1w8ivjd::placeholder {
  color: #737588;
}

/*Form dropdown - Single Scrub*/
.mantine-1u5idp6 {
  color: #223c78;
}

/*Form button - Single Scrub*/
.mantine-1x64jh2 {
  background-color: #223c78;
}

/*Accordion button - Single Scrub*/
.mantine-9059tm {
  background-color: #2bace2;
}

.mantine-v4lv9f {
  border-bottom: transparent;
}

/*No funciona y no se va el icono*/
/*.mantine-v4lv9f:hover{
    background-color: red;
}*/

/*Box borderColor - Phone Scrub 2*/
.mantine-4716ii {
  border: 1px solid #d1d5db;
}

/*Below colorCards Btn - Phone Scrub 2*/
.mantine-gkqjuz {
  background-color: #223c78;
}

/* Basic styles for nav links */
nav a {
  display: flex;
  align-items: center;
  padding-left: 30px;
  padding-right: 30px;
  height: 56px;
  text-decoration: none;
}

/* Specific styles for non-active links */
.link {
  color: #809fb8;
}

/* Specific styles for active links */
.link-active {
  color: white;
  background: #223c78;
  border-radius: 10px;
}

/*Tooltip textColor - Phone Scrub 2*/
.mantine-Tooltip-body {
  color: #626262;
  font-size: 12px;
}

/*Pagination Button*/
.mantine-Pagination-item[data-active] {
  background-color: #223c78;
}

/*Button Back to list padding*/
.mantine-1cnnrxk {
  padding: 0px;
}

/*Text style - Input form profile*/
.mantine-1w07r5r {
  color: #223c78;
  font-size: 16px;
}

.image-hover {
  filter: brightness(0) invert(1);
}

.text-webkit-center {
  text-align: -webkit-center;
}

.log-modal:nth-child(even) {
  background-color: rgba(128, 128, 128, 0.31);
}

.lenderShadow {
  box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.25);
}

.yellow-star {
  color: #223c78;
}

.dialog-container {
  position: block;
  z-index: 9;
}

.dialog-backdrop {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(4px);
  z-index: 10;
}

.dialog-content {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  background-color: #fff;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  z-index: 11;
}

.dialog-container.open .dialog-backdrop {
  display: block;
}

.dialog-container.open .dialog-content {
  display: block;
}

.separator {
  @apply border-t border-[#DFF1FF] border;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.quill ul {
  list-style-type: disc;
}
.quill ol {
  list-style-type: point;
}

.quill {
  height: 100%;
  max-height: 100%;
}
.moreAbout {
}
.briefDescription {
}

.moreAbout .quill .ql-editor {
  height: 150px;
  max-height: 150px;
  overflow-y: auto;
}

.mantine-10mblmp {
  min-height: unset !important;
  width: 100%;
  height: unset !important;
  -webkit-appearance: none;
  line-height: 21px;
  padding-left: 5px;
  padding-top: 0px;
}

.twolinestext {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.filterNotButton:active {
  border-style: outset;
  transform: none !important;
}
